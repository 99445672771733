// Generated by Framer (97d1eee)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/Fy7yHN3BQ";

const enabledGestures = {P7CGjJgSi: {hover: true}};

const cycleOrder = ["P7CGjJgSi", "sF38oxr5C"];

const serializationHash = "framer-KM7uw"

const variantClassNames = {P7CGjJgSi: "framer-v-1jg4v0v", sF38oxr5C: "framer-v-4xr7vv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Aktiv: "sF38oxr5C", Resting: "P7CGjJgSi"}

const getProps = ({click, filter, height, id, width, ...props}) => { return {...props, mJGAY8Fci: filter ?? props.mJGAY8Fci ?? "Filter", NKteD1tgg: click ?? props.NKteD1tgg, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "P7CGjJgSi"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;filter?: string;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, mJGAY8Fci, NKteD1tgg, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "P7CGjJgSi", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1vpvwcg = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (NKteD1tgg) {const res = await NKteD1tgg(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1jg4v0v", className, classNames)} data-framer-name={"Resting"} data-highlight layoutDependency={layoutDependency} layoutId={"P7CGjJgSi"} onTap={onTap1vpvwcg} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, ...style}} variants={{"P7CGjJgSi-hover": {backgroundColor: "rgba(0, 0, 0, 0.05)"}, sF38oxr5C: {backgroundColor: "var(--token-628840d5-92fe-4992-89b3-e612e3517bed, rgb(26, 32, 39))"}}} {...addPropertyOverrides({"P7CGjJgSi-hover": {"data-framer-name": undefined}, sF38oxr5C: {"data-framer-name": "Aktiv"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1lb05oh"} data-styles-preset={"Fy7yHN3BQ"}>Tab</motion.p></React.Fragment>} className={"framer-1omrq9u"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"M_IAv3zVG"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={mJGAY8Fci} variants={{sF38oxr5C: {"--extracted-r6o4lv": "var(--token-327bd4e3-b19a-4af0-9475-1a7d5459bf1f, rgb(255, 255, 255))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({sF38oxr5C: {children: <React.Fragment><motion.p className={"framer-styles-preset-1lb05oh"} data-styles-preset={"Fy7yHN3BQ"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-327bd4e3-b19a-4af0-9475-1a7d5459bf1f, rgb(255, 255, 255)))"}}>Filter</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KM7uw.framer-1xd45pk, .framer-KM7uw .framer-1xd45pk { display: block; }", ".framer-KM7uw.framer-1jg4v0v { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 8px 16px 8px 16px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-KM7uw .framer-1omrq9u { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KM7uw.framer-1jg4v0v { gap: 0px; } .framer-KM7uw.framer-1jg4v0v > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-KM7uw.framer-1jg4v0v > :first-child { margin-left: 0px; } .framer-KM7uw.framer-1jg4v0v > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 69.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"sF38oxr5C":{"layout":["auto","auto"]},"Em8f51kBX":{"layout":["auto","auto"]}}}
 * @framerVariables {"mJGAY8Fci":"filter","NKteD1tgg":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNUtzUs3wO: React.ComponentType<Props> = withCSS(Component, css, "framer-KM7uw") as typeof Component;
export default FramerNUtzUs3wO;

FramerNUtzUs3wO.displayName = "Arbeider Filter Knapp";

FramerNUtzUs3wO.defaultProps = {height: 35, width: 69.5};

addPropertyControls(FramerNUtzUs3wO, {variant: {options: ["P7CGjJgSi", "sF38oxr5C"], optionTitles: ["Resting", "Aktiv"], title: "Variant", type: ControlType.Enum}, mJGAY8Fci: {defaultValue: "Filter", displayTextArea: false, title: "Filter", type: ControlType.String}, NKteD1tgg: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerNUtzUs3wO, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQ bJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})